import React from "react";
import { NavLink } from "react-router-dom";
function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer__wrapper">
            <div className="footer__left">
              <img
                className="footer__logo"
                width={200}
                src="./assets/icons/logo.svg"
                alt=""
              />
              <p className="footer__description">
                We are wizard of the web, merging creativity and technology
              </p>
              <span className="footer__copyright">
                2023 Wixard Studio. All Rights Reserved.
              </span>
            </div>
            <div className="footer__menu">
              <div className="footer__list">
                <span className="footer__list-title">
                  <img src="./assets/icons/footer-arrow.svg" alt="" />
                  Company
                </span>
                <ul>
                  <li>
                    <NavLink to="/#ourServices">Services</NavLink>
                  </li>
                  <li>
                    <NavLink to="/#digitalStudio">Industries</NavLink>
                  </li>
                </ul>
              </div>
              <div className="footer__list">
                <span className="footer__list-title">
                  <img src="./assets/icons/footer-arrow.svg" alt="" />
                  Services
                </span>
                <ul>
                  <li>
                    <NavLink to="/works">Works</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about">About</NavLink>
                  </li>
                  <li>
                    <NavLink to="mailto:info@wixard.co">Contact</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer__social">
              <a href="http://Instagram.com/wixard.co">
                <div className="footer__social-item">
                  <img src="/assets/icons/instagram.svg" alt="instagram" />
                </div>
              </a>
              <a href="https://www.linkedin.com/wixard">
                <div className="footer__social-item">
                  <img src="/assets/icons/linkedin.svg" alt="linkedin" />
                </div>
              </a>
              <a href="https://www.behance.net/wixard">
                <div className="footer__social-item">
                  <img src="/assets/icons/behance.svg" alt="behance" />
                </div>
              </a>
              <a href="https://dribbble.com/wixard">
                <div className="footer__social-item">
                  <img src="/assets/icons/dribble.svg" alt="dribble" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
