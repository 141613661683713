import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HomeIntro from "../components/HomeIntro";
import OurServicess from "../components/OurServicess";
import DigitalStudio from "../components/DigitalStudio";
import Questions from "../components/Questions";

function HomeBody() {
  useEffect(() => {
    document.title = "Home | Wixard";
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const section = document.querySelector(location.hash);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, [location.pathname, location.hash]);

  return (
    <>
      <HomeIntro />
      <OurServicess />
      <DigitalStudio />

      <Questions />
    </>
  );
}

export default HomeBody;
