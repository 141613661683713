import React, { useEffect } from "react";
import WorksIntro from "../components/WorksIntro";
import Works from "../components/Works";
import Questions from "../components/Questions";

function WorksBody() {
  useEffect(() => {
    document.title = "Works | Wixard";
  }, []);
  return (
    <>
      <WorksIntro />
      <Works />
      <Questions />
    </>
  );
}

export default WorksBody;
