import React from "react";
import Spline from "@splinetool/react-spline";
function HomeIntro() {
  return (
    <>
      <section id="homeIntro" className="homeIntro">
        <div className="container">
          <div className="homeIntro__wrapper">
            <div className="homeIntro__content">
              <h1 className="homeIntro__headline">
                We are the
                <span className="homeIntro__highlight mx-1">
                  <span className="homeIntro__highlight-container">
                    <span className="homeIntro__highlight-wizards">
                      wizards
                    </span>
                    <span className="homeIntro__highlight-wixards">
                      wixards
                    </span>
                  </span>
                </span>
                of the
                <br />
                web, merging creativity and technology.
              </h1>
              <a href="">
                <button className="btn btn__kedavra">
                  <span className="btn-text-kedavra">
                    Avada Kedavra
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={11}
                      viewBox="0 0 12 11"
                      fill="none"
                    >
                      <path
                        d="M1.57449 9.66664L10.1754 0.999972M10.1754 0.999972V9.31997M10.1754 0.999972L1.91853 0.999972"
                        stroke="url(#paint0_linear_21_1701)"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_21_1701"
                          x1="1.57446"
                          y1="5.33333"
                          x2="10.1754"
                          y2="5.33333"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#22F7DD" />
                          <stop offset={1} stopColor="#B4FDC2" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span className="btn-text-magic">
                    Start For Magic
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={11}
                      viewBox="0 0 12 11"
                      fill="none"
                    >
                      <path
                        d="M1.57449 9.66664L10.1754 0.999972M10.1754 0.999972V9.31997M10.1754 0.999972L1.91853 0.999972"
                        stroke="black"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </a>
            </div>
            <div className="homeIntro__orb">
              <Spline scene="https://prod.spline.design/skWlhDFDz7ezXgk0/scene.splinecode" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeIntro;
