import React, { useEffect } from "react";
import AboutIntro from "../components/AboutIntro";
import Wingardium from "../components/Wingardium";
import AboutQuestions from "../components/AboutQuestions";
import WeUse from "../components/WeUse";
import AboutCenterTitle from "../components/AboutCenterTitle";

function AboutBody() {
  useEffect(() => {
    document.title = "About | Wixard";
  }, []);
  return (
    <>
      <AboutIntro />
      <Wingardium />
      <AboutQuestions />
      <WeUse />
      <AboutCenterTitle />
    </>
  );
}

export default AboutBody;
