import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const getNavLinkClass = (path, hash) => {
    const isActive = location.pathname === path && location.hash === hash;
    return `nav-link${isActive ? " active" : ""}`;
  };
  

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <NavLink className="navbar-brand d-block d-lg-none" to="/">
              <img width={200} src="/assets/icons/logo.svg" alt="logo" />
            </NavLink>
            <button
              type="button"
              className="btn btn__lumos navbar-toggler"
              aria-controls="wixardNavbar"
              aria-label="Toggle navigation"
              onClick={toggleMenu}
            >
              <span className="btn-text-lumos">Lumos</span>
              <span className="btn-text-contact">Lumos</span>
            </button>
            <div
              className={`offcanvas offcanvas-start ${
                isMenuOpen ? "show" : ""
              }`}
              tabIndex={-1}
              id="wixardNavbar"
              aria-labelledby="wixardNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="wixardNavbarLabel">
                  <img src="./assets/icons/wixard-star.svg" alt="logo" />
                </h5>
                <button
                  type="button"
                  className="btn btn__lumos"
                  aria-label="Close"
                  onClick={closeMenu}
                >
                  <span className="btn-text-lumos">Lumos</span>
                  <span className="btn-text-contact">Lumos</span>
                </button>
              </div>
              <div className="offcanvas-body">
                <NavLink className="navbar-brand d-none d-lg-block" to="/">
                  <img width={200} src="./assets/icons/logo.svg" alt="logo" />
                </NavLink>
                <ul className="navbar-nav">
                  {/* <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      aria-current="page"
                      to="/works"
                      onClick={closeMenu}
                    >
                      Works
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      to="/#ourServices"
                      className={() => getNavLinkClass("/", "#ourServices")}
                      onClick={closeMenu}
                    >
                      Services
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/#digitalStudio"
                      className={() => getNavLinkClass("/", "#digitalStudio")}
                      onClick={closeMenu}
                    >
                      Industries
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      aria-current="page"
                      to="/about"
                      onClick={closeMenu}
                    >
                      About
                    </NavLink>
                  </li>
                </ul>
                <div className="navbar__footer">
                  <div className="navbar__social">
                    <NavLink to="http://Instagram.com/wixard.co">
                      <div className="navbar__social-item">
                        <img
                          src="./assets/icons/instagram.svg"
                          alt="instagram"
                        />
                      </div>
                    </NavLink>
                    <NavLink to="https://www.linkedin.com/wixard">
                      <div className="navbar__social-item">
                        <img src="./assets/icons/linkedin.svg" alt="linkedin" />
                      </div>
                    </NavLink>
                    <NavLink to="https://www.behance.net/wixard">
                      <div className="navbar__social-item">
                        <img src="./assets/icons/behance.svg" alt="behance" />
                      </div>
                    </NavLink>
                    <NavLink to="https://dribbble.com/wixard">
                      <div className="navbar__social-item">
                        <img src="./assets/icons/dribble.svg" alt="dribble" />
                      </div>
                    </NavLink>
                  </div>
                  <NavLink
                    to="mailto:info@wixard.co"
                    className="navbar__footer-mail"
                  >
                    info@wixard.co
                  </NavLink>
                </div>
                <NavLink
                  to="mailto:info@wixard.co"
                  className="d-none d-lg-block"
                >
                  <button className="btn btn__lumos">
                    <span className="btn-text-lumos">Lumos</span>
                    <span className="btn-text-contact">Contact Us</span>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
