import React from "react";
function Works() {
  return (
    <>
      <section className="works">
        <div className="container">
          <div className="works__nav">
            {/* item */}
            <div className="works__nav-item active">
              <span>All Case</span>
            </div>
            {/* item */}
            <div className="works__nav-item">
              <span>Branding</span>
            </div>
            {/* item */}
            <div className="works__nav-item">
              <span>Web 3.0</span>
            </div>
            {/* item */}
            <div className="works__nav-item">
              <span>Game Platform</span>
            </div>
            {/* item */}
            <div className="works__nav-item">
              <span>Fintech</span>
            </div>
            {/* item */}
            <div className="works__nav-item">
              <span>Crypto</span>
            </div>
            {/* item */}
            <div className="works__nav-item">
              <span>Healtcare</span>
            </div>
            {/* item */}
            <div className="works__nav-item">
              <span>Food</span>
            </div>
          </div>
          <div className="works__wrapper">
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works1.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Fresh Merch</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works2.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Laber</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works3.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Trust Token</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works2.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Laber</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works1.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Fresh Merch</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works2.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Laber</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works3.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Trust Token</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works2.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Laber</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works1.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Fresh Merch</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works2.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Laber</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works3.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Trust Token</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
            {/* item */}
            <a href="">
              <div className="works__item">
                <div className="works__item-img">
                  <img src="./assets/images/works2.png" alt="" />
                </div>
                <div className="works__item-content">
                  <span className="works__item-title">Laber</span>
                  <p className="works__item-description">
                    We magically create your brand to define the product in the
                    digital environment.
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Works;
