import React from "react";
function WeUse() {
  return (
    <>
      <section id="weUse" className="weUse">
        <div className="container">
          <div className="weUse__content">
            <div className="weUse__left">
              <h2 className="weUse__headline">
                What <span>We Use</span>
              </h2>
              <p className="weUse__description">
                We prefer the most <span>up-to-date</span> and powerful
                applications
              </p>
            </div>
            <div className="weUse__tech">
              <div className="weUse__wrapper">
                <img src="./assets/icons/adobe.svg" alt="adobe" />
                <img src="./assets/icons/framer.svg" alt="framer" />
                <img src="./assets/icons/figma.svg" alt="figma" />
                <img src="./assets/icons/github.svg" alt="github" />
                <img src="./assets/icons/webflow.svg" alt="webflow" />
                <img src="./assets/icons/java.svg" alt="java" />
                <img src="./assets/icons/notion.svg" alt="notion" />
                <img src="./assets/icons/aws.svg" alt="aws" />
                <img src="./assets/icons/slack.svg" alt="slack" />
                <img src="./assets/icons/angular.svg" alt="angular" />
                <img src="./assets/icons/nuxt.svg" alt="nuxt" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WeUse;
