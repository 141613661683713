import React from "react";
function Wingardium() {
  return (
    <>
      <div className="wingardium">
        <img src="./assets/icons/wingardium.svg" alt="" />
        <div className="wingardium__arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={32}
            height={32}
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M16 1.0001L16 31.0001M16 31.0001L1.6 16.6001M16 31.0001L30.4 16.6001"
              stroke="white"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default Wingardium;
