import React from "react";
function DigitalStudio() {
  return (
    <>
      <section id="digitalStudio" className="digitalStudio">
        <div className="container">
          <div className="digitalStudio__wrapper">
            <div className="wixard__container" data-aos="fade-right">
              <div className="wixard__content">
                <div className="wixard__box">
                  <svg
                    width={356}
                    height={262}
                    viewBox="0 0 356 262"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M237.791 74.484H74.409L-102 -102.468H417.457L237.791 74.484Z"
                      fill="url(#paint0_linear_465_6036)"
                      fillOpacity="0.16"
                    />
                    <g filter="url(#filter0_f_465_6036)">
                      <path
                        d="M180.5 257C261.41 257 327 191.41 327 110.5C327 29.5903 261.41 -36 180.5 -36C99.5903 -36 34 29.5903 34 110.5C34 191.41 99.5903 257 180.5 257Z"
                        fill="url(#paint1_radial_465_6036)"
                        fillOpacity="0.05"
                      />
                    </g>
                    <path
                      d="M237.503 241.123L286.643 195.08V28.347L237.503 74.3891V195.081V241.123Z"
                      fill="url(#paint2_linear_465_6036)"
                      fillOpacity="0.06"
                    />
                    <path
                      d="M123.006 195.081L73.866 241.123V74.3891L123.006 28.347V74.3891V195.081Z"
                      fill="url(#paint3_linear_465_6036)"
                      fillOpacity="0.06"
                    />
                    <path
                      d="M123.006 195.081L73.866 241.123H237.503V195.081H123.006Z"
                      fill="url(#paint4_linear_465_6036)"
                      fillOpacity="0.06"
                    />
                    <path
                      d="M123.006 74.3892V195.081H237.503V74.3892H123.006Z"
                      fill="url(#paint5_linear_465_6036)"
                      fillOpacity="0.06"
                    />
                    <path
                      d="M237.503 74.3891L286.643 28.347H123.006V74.3891H237.503Z"
                      fill="url(#paint6_linear_465_6036)"
                      fillOpacity="0.06"
                    />
                    <path
                      d="M286.643 195.08L237.503 241.123M286.643 195.08V28.347M286.643 195.08L237.503 195.081M237.503 241.123H73.866M237.503 241.123V195.081M286.643 28.347L237.503 74.3891M286.643 28.347H123.006M237.503 195.081H123.006M237.503 195.081V74.3891M73.866 241.123L123.006 195.081M73.866 241.123V74.3891M123.006 195.081V74.3891M73.866 74.3891L123.006 28.347M73.866 74.3891H123.006M123.006 74.3891H237.503M123.006 74.3891V28.347"
                      stroke="white"
                      strokeOpacity="0.24"
                      strokeWidth="1.2"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M123.006 195.081L73.866 241.123H237.503V195.081H123.006Z"
                      fill="url(#paint7_linear_465_6036)"
                      fillOpacity="0.16"
                    />
                    <path
                      d="M237.503 74.3889L286.643 28.3469H123.006V74.3889H237.503Z"
                      fill="url(#paint8_linear_465_6036)"
                      fillOpacity="0.16"
                    />
                    <path
                      d="M123.006 28.3469L73.866 74.3889H123.006V28.3469Z"
                      fill="url(#paint9_linear_465_6036)"
                      fillOpacity="0.16"
                    />
                    <path
                      d="M237.503 241.123L286.643 195.08L237.503 195.081V241.123Z"
                      fill="url(#paint10_linear_465_6036)"
                      fillOpacity="0.16"
                    />
                    <path
                      d="M119.806 195.082C119.806 196.849 121.239 198.282 123.006 198.282C124.774 198.282 126.206 196.849 126.206 195.082C126.206 193.315 124.774 191.882 123.006 191.882C121.239 191.882 119.806 193.315 119.806 195.082ZM283.443 195.082C283.443 196.849 284.876 198.282 286.643 198.282C288.411 198.282 289.843 196.849 289.843 195.082C289.843 193.315 288.411 191.882 286.643 191.882C284.876 191.882 283.443 193.315 283.443 195.082ZM123.006 195.682H286.643V194.482H123.006V195.682Z"
                      fill="white"
                      fillOpacity="0.64"
                    />
                    <path
                      d="M119.806 28.347C119.806 30.114 121.239 31.547 123.006 31.547C124.774 31.547 126.206 30.114 126.206 28.347C126.206 26.579 124.774 25.147 123.006 25.147C121.239 25.147 119.806 26.579 119.806 28.347ZM283.443 28.347C283.443 30.114 284.876 31.547 286.643 31.547C288.411 31.547 289.843 30.114 289.843 28.347C289.843 26.579 288.411 25.147 286.643 25.147C284.876 25.147 283.443 26.579 283.443 28.347ZM123.006 28.947H286.643V27.747H123.006V28.947Z"
                      fill="white"
                      fillOpacity="0.64"
                    />
                    <path
                      d="M234.303 241.123C234.303 242.89 235.736 244.323 237.503 244.323C239.271 244.323 240.703 242.89 240.703 241.123C240.703 239.356 239.271 237.923 237.503 237.923C235.736 237.923 234.303 239.356 234.303 241.123ZM70.666 241.123C70.666 242.89 72.099 244.323 73.866 244.323C75.634 244.323 77.066 242.89 77.066 241.123C77.066 239.356 75.634 237.923 73.866 237.923C72.099 237.923 70.666 239.356 70.666 241.123ZM237.503 240.523H73.866V241.723H237.503V240.523Z"
                      fill="white"
                      fillOpacity="0.64"
                    />
                    <path
                      d="M234.303 74.388C234.303 76.155 235.736 77.588 237.503 77.588C239.271 77.588 240.703 76.155 240.703 74.388C240.703 72.62 239.271 71.188 237.503 71.188C235.736 71.188 234.303 72.62 234.303 74.388ZM70.666 74.388C70.666 76.155 72.099 77.588 73.866 77.588C75.634 77.588 77.066 76.155 77.066 74.388C77.066 72.62 75.634 71.188 73.866 71.188C72.099 71.188 70.666 72.62 70.666 74.388ZM237.503 73.788H73.866V74.988H237.503V73.788Z"
                      fill="white"
                      fillOpacity="0.64"
                    />
                    <defs>
                      <filter
                        id="filter0_f_465_6036"
                        x={-146}
                        y={-216}
                        width={653}
                        height={653}
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity={0} result="BackgroundImageFix" />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feGaussianBlur
                          stdDeviation={90}
                          result="effect1_foregroundBlur_465_6036"
                        />
                      </filter>
                      <linearGradient
                        id="paint0_linear_465_6036"
                        x1="157.729"
                        y1={-102}
                        x2="157.729"
                        y2="153.5"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#030D0C" stopOpacity={0} />
                        <stop offset={1} stopColor="white" />
                      </linearGradient>
                      <radialGradient
                        id="paint1_radial_465_6036"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(180.5 113.35) rotate(90) scale(343.448)"
                      >
                        <stop />
                        <stop offset={1} stopOpacity={0} />
                      </radialGradient>
                      <linearGradient
                        id="paint2_linear_465_6036"
                        x1="180.255"
                        y1="28.347"
                        x2="180.255"
                        y2="241.123"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset={1} stopColor="white" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_465_6036"
                        x1="180.255"
                        y1="28.347"
                        x2="180.255"
                        y2="241.123"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset={1} stopColor="white" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient
                        id="paint4_linear_465_6036"
                        x1="180.255"
                        y1="28.3471"
                        x2="180.255"
                        y2="241.123"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset={1} stopColor="white" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient
                        id="paint5_linear_465_6036"
                        x1="180.255"
                        y1="28.3472"
                        x2="180.255"
                        y2="241.123"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset={1} stopColor="white" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient
                        id="paint6_linear_465_6036"
                        x1="180.255"
                        y1="28.347"
                        x2="180.255"
                        y2="241.123"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset={1} stopColor="white" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient
                        id="paint7_linear_465_6036"
                        x1="292.614"
                        y1="28.3471"
                        x2="73.866"
                        y2="75.5701"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset={1} stopColor="white" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient
                        id="paint8_linear_465_6036"
                        x1="292.614"
                        y1="28.3469"
                        x2="73.8661"
                        y2="75.5699"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset={1} stopColor="white" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient
                        id="paint9_linear_465_6036"
                        x1="292.614"
                        y1="28.3469"
                        x2="73.866"
                        y2="75.5699"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset={1} stopColor="white" stopOpacity={0} />
                      </linearGradient>
                      <linearGradient
                        id="paint10_linear_465_6036"
                        x1="292.614"
                        y1="28.3471"
                        x2="73.8659"
                        y2="75.5701"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset={1} stopColor="white" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="wixard__circle" />
                <div className="wixard__x">
                  <svg
                    width={298}
                    height={258}
                    viewBox="0 0 298 258"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M191.121 198.799C179.436 180.515 160.014 167.54 136.695 167.559C127.665 167.567 119.293 171.011 111.207 174.637C139.891 153.75 129.241 116.052 117.191 89.4447C130.244 111.788 157.035 127.332 181.278 114.03C167.636 142.773 176.464 172.598 191.121 198.799Z"
                      stroke="url(#paint0_linear_1_285)"
                      strokeWidth="4.87"
                      strokeMiterlimit="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1_285"
                        x1="110.536"
                        y1="173.646"
                        x2="186.725"
                        y2="122.077"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#22F7DD" />
                        <stop offset={1} stopColor="#B4FDC2" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className="digitalStudio__content">
              <h3 className="digitalStudio__headline" data-aos="fade-left">
                Work with the most effective
                <span>Digital Studio</span> team.
              </h3>
              <p className="digitalStudio__description" data-aos="fade">
                With more than <span>500 startups</span> and successful
                projects, we know how to make your brand shine.
              </p>
              <ul className="digitalStudio__category" data-aos="fade">
                <li>
                  <a>Brand Identity</a>
                </li>
                <li>
                  <a>UX/UI Design</a>
                </li>
                <li>
                  <a>Web Design &amp; Dev.</a>
                </li>
                <li>
                  <a>Web3/Blockchain</a>
                </li>
                <li>
                  <a>DevOps system</a>
                </li>
                <li>
                  <a>E-Commerce System</a>
                </li>
                <li>
                  <a>Graphic Design</a>
                </li>
                <li>
                  <a>NFT / Game Design</a>
                </li>
              </ul>
              <p className="digitalStudio__description">
                Choose the service you want and see the lowest planning
              </p>
              <div className="digitalStudio__footer">
                <a href="mailto:info@wixard.co">
                  <button type="button" className="btn btn__rainbow">
                    Let’s get to work
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={11}
                      height={11}
                      viewBox="0 0 11 11"
                      fill="none"
                    >
                      <path
                        d="M1.00003 9.66664L9.6667 0.999972M9.6667 0.999972V9.31997M9.6667 0.999972L1.3467 0.999972"
                        stroke="black"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </a>
                <div>
                  <p className="digitalStudio__description">
                    Estimated cost &amp; time → <span>$ 3,000 / 1 week</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DigitalStudio;
