import React from "react";
function AboutCenterTitle() {
  return (
    <>
      <section className="centerTitle lightning-center">
        <div className="container">
          <div className="centerTitle__content">
            <h3 className="headline mb-4">
              Let’s Start Your
              <span>Dreams Today</span>
            </h3>
            <a href="mailto:info@wixard.co">
              <button className="btn btn__kedavra">
                <span className="btn-text-kedavra">
                  Avada Kedavra
                  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={11} viewBox="0 0 12 11" fill="none">
                    <path
                      d="M1.57449 9.66664L10.1754 0.999972M10.1754 0.999972V9.31997M10.1754 0.999972L1.91853 0.999972"
                      stroke="url(#paint0_linear_21_1701)"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_21_1701"
                        x1="1.57446"
                        y1="5.33333"
                        x2="10.1754"
                        y2="5.33333"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#22F7DD" />
                        <stop offset={1} stopColor="#B4FDC2" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="btn-text-magic">
                  Start For Magic
                  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={11} viewBox="0 0 12 11" fill="none">
                    <path
                      d="M1.57449 9.66664L10.1754 0.999972M10.1754 0.999972V9.31997M10.1754 0.999972L1.91853 0.999972"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutCenterTitle;
