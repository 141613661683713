import React from "react";
function Questions() {
  return (
    <>
      <section id="Questions" className="questions">
        <div className="container">
          <div className="questions__wrapper">
            <div className="questions__text">
              <h4 className="questions__headline" data-aos="fade-right">
                Frequently Asked Questions
              </h4>
              <p className="questions__description" data-aos="fade">
                You have a question that is not answered? You can contact us at{" "}
                <a href="mailto:support@wixard.co">support@wixard.co</a>
              </p>
            </div>
            <div className="accordion" id="questionsAccordion">
              <div className="accordion-item" data-aos="fade">
                <span className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#question1"
                    aria-expanded="true"
                    aria-controls="question1"
                  >
                    What is Wixard?
                  </button>
                </span>
                <div id="question1" className="accordion-collapse collapse show" data-bs-parent="#questionsAccordion">
                  <div className="accordion-body">
                    <span>Wixard</span> is not just a name; it encapsulates a vision, a commitment to digital
                    brilliance. We are more than a digital studio; we are artisans of the virtual environment, shaping
                    immersive experiences that resonate with excellence. With every keystroke, every pixel, we create
                    magic that elevates businesses beyond the ordinary.
                  </div>
                </div>
              </div>
              {/* item */}
              <div className="accordion-item" data-aos="fade">
                <span className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#question2"
                    aria-expanded="false"
                    aria-controls="question2"
                  >
                    What is a Magic Potion, and what does it include?
                  </button>
                </span>
                <div id="question2" className="accordion-collapse collapse" data-bs-parent="#questionsAccordion">
                  <div className="accordion-body">
                    <span>Behold</span>, the Magic Potion, our sacred elixir crafted with care and finesse! Within its
                    mystical blend, you'll find bespoke web design, custom development, and the essence of seamless user
                    experiences. But that's not all! It's also infused with the magic of strategic digital marketing,
                    ensuring your online presence casts a spell like never before.
                  </div>
                </div>
              </div>
              {/* item */}
              <div className="accordion-item" data-aos="fade">
                <span className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#question3"
                    aria-expanded="false"
                    aria-controls="question3"
                  >
                    Amidst a sea of digital options, why entrust your dreams to the mystical arts of Wixard?
                  </button>
                </span>
                <div id="question3" className="accordion-collapse collapse" data-bs-parent="#questionsAccordion">
                  <div className="accordion-body">
                    In a world where pixels reign supreme, <span>Wixard</span> stands as a beacon of digital sorcery!
                    Our team of seasoned wizards combines creativity with technical prowess, weaving spells that
                    transcend the ordinary. With <span>Wixard</span>, your digital dreams become vivid realities,
                    illuminated by the glow of our digital cauldron.
                  </div>
                </div>
              </div>
              {/* item */}
              <div className="accordion-item" data-aos="fade">
                <span className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#question4"
                    aria-expanded="false"
                    aria-controls="question4"
                  >
                    Can I sprinkle a little magic on my project if I have some changes in mind?
                  </button>
                </span>
                <div id="question4" className="accordion-collapse collapse" data-bs-parent="#questionsAccordion">
                  <div className="accordion-body">
                    Ah, the beauty of our enchanting process lies in its meticulous crafting. While we strive to keep
                    our pricing competitive, we do have a secret ingredient: limited edits per concept. Think of it as
                    adding a dash of spice to your potion. We're happy to make small adjustments to copy and layouts,
                    but for any grand transformations, let's brew up a new concoction, shall we?
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Questions;
