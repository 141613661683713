import React from "react";
function AboutQuestions() {
  return (
    <>
      <section className="questions lightning-center">
        <div className="container">
          <div className="questions__wrapper align-items-start gap-md-3 gap-5">
            <div className="accordion" id="questionsAccordion">
              <div className="accordion-item" data-aos="fade">
                <span className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#question1"
                    aria-expanded="true"
                    aria-controls="question1"
                  >
                    What is Wixard?
                  </button>
                </span>
                <div id="question1" className="accordion-collapse collapse show" data-bs-parent="#questionsAccordion">
                  <div className="accordion-body">
                    <span>Wixard</span> is not just a name; it encapsulates a vision, a commitment to digital
                    brilliance. We are more than a digital studio; we are artisans of the virtual environment, shaping
                    immersive experiences that resonate with excellence. With every keystroke, every pixel, we create
                    magic that elevates businesses beyond the ordinary.
                  </div>
                </div>
              </div>
              {/* item */}
              <div className="accordion-item" data-aos="fade">
                <span className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#question2"
                    aria-expanded="false"
                    aria-controls="question2"
                  >
                    What is a Magic Potion, and what does it include?
                  </button>
                </span>
                <div id="question2" className="accordion-collapse collapse" data-bs-parent="#questionsAccordion">
                  <div className="accordion-body">
                    <span>Behold</span>, the Magic Potion, our sacred elixir crafted with care and finesse! Within its
                    mystical blend, you'll find bespoke web design, custom development, and the essence of seamless user
                    experiences. But that's not all! It's also infused with the magic of strategic digital marketing,
                    ensuring your online presence casts a spell like never before.
                  </div>
                </div>
              </div>
              {/* item */}
              <div className="accordion-item" data-aos="fade">
                <span className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#question3"
                    aria-expanded="false"
                    aria-controls="question3"
                  >
                    Amidst a sea of digital options, why entrust your dreams to the mystical arts of Wixard?
                  </button>
                </span>
                <div id="question3" className="accordion-collapse collapse" data-bs-parent="#questionsAccordion">
                  <div className="accordion-body">
                    In a world where pixels reign supreme, <span>Wixard</span> stands as a beacon of digital sorcery!
                    Our team of seasoned wizards combines creativity with technical prowess, weaving spells that
                    transcend the ordinary. With <span>Wixard</span>, your digital dreams become vivid realities,
                    illuminated by the glow of our digital cauldron.
                  </div>
                </div>
              </div>
              {/* item */}
              <div className="accordion-item" data-aos="fade">
                <span className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#question4"
                    aria-expanded="false"
                    aria-controls="question4"
                  >
                    Can I sprinkle a little magic on my project if I have some changes in mind?
                  </button>
                </span>
                <div id="question4" className="accordion-collapse collapse" data-bs-parent="#questionsAccordion">
                  <div className="accordion-body">
                    Ah, the beauty of our enchanting process lies in its meticulous crafting. While we strive to keep
                    our pricing competitive, we do have a secret ingredient: limited edits per concept. Think of it as
                    adding a dash of spice to your potion. We're happy to make small adjustments to copy and layouts,
                    but for any grand transformations, let's brew up a new concoction, shall we?
                  </div>
                </div>
              </div>
            </div>
            <div className="questions__text text-start gap-0 ">
              <h4 className="questions__headline">Learn About Wixard Studio</h4>
              <p className="questions__description">
                You have a question that is not answered? You can contact us at{" "}
                <a href="mailto:support@wixard.co">support@wixard.co</a>
              </p>
              <div className="wixard__container">
                <div className="wixard__content">
                  <div className="wixard__coin">
                    <svg width={153} height={75} viewBox="0 0 153 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.36015 57.2468C-2.64785 44.5918 27.2562 23.8318 68.1542 10.8768C109.051 -2.07724 145.455 -2.32024 149.463 10.3338L151.905 18.0418C152.309 19.3178 152.368 20.6778 152.106 22.1028C149.766 34.8058 121.882 52.7638 85.1112 64.4108C48.3402 76.0578 15.2021 77.4298 5.97615 68.3908C4.94015 67.3758 4.20616 66.2308 3.80116 64.9538L1.36015 57.2468Z"
                        fill="url(#paint0_linear_1_286)"
                        fillOpacity="0.16"
                      />
                      <path
                        d="M149.463 10.3338C145.455 -2.32024 109.051 -2.07724 68.1542 10.8768C27.2562 23.8318 -2.64785 44.5918 1.36015 57.2468M149.463 10.3338C149.868 11.6108 149.927 12.9708 149.664 14.3958C149.657 14.4368 149.649 14.4778 149.641 14.5178C147.146 27.2138 119.323 45.0938 82.6702 56.7038C46.0162 68.3138 12.9732 69.7138 3.62315 60.7698C3.59315 60.7408 3.56416 60.7118 3.53416 60.6828C2.49916 59.6688 1.76515 58.5228 1.36015 57.2468M149.463 10.3338L151.905 18.0418C152.309 19.3178 152.368 20.6778 152.106 22.1028C149.766 34.8058 121.882 52.7638 85.1112 64.4108C48.3402 76.0578 15.2021 77.4298 5.97615 68.3908C4.94015 67.3758 4.20616 66.2308 3.80116 64.9538L1.36015 57.2468"
                        stroke="white"
                        strokeOpacity="0.16"
                        strokeWidth="1.21539"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.53412 60.6837C2.49912 59.6697 1.76511 58.5237 1.36011 57.2467L3.80112 64.9537C4.20612 66.2307 4.9401 67.3767 5.9761 68.3907C15.2021 77.4307 48.3401 76.0587 85.1111 64.4117C121.882 52.7637 149.766 34.8067 152.106 22.1037C152.368 20.6787 152.309 19.3187 151.905 18.0417L149.463 10.3347C149.868 11.6117 149.927 12.9707 149.664 14.3967C149.657 14.4377 149.649 14.4777 149.641 14.5187C147.147 27.2147 119.323 45.0937 82.6701 56.7037C46.0161 68.3147 12.9731 69.7147 3.62411 60.7707C3.59412 60.7417 3.56412 60.7127 3.53412 60.6837Z"
                        fill="url(#paint1_linear_1_286)"
                        fillOpacity="0.16"
                        stroke="white"
                        strokeOpacity="0.16"
                        strokeWidth="1.21539"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M68.1542 10.8768C27.2562 23.8318 -2.64785 44.5918 1.36015 57.2468C1.76515 58.5228 2.49916 59.6688 3.53416 60.6828C3.56416 60.7118 3.59315 60.7408 3.62315 60.7698C12.9732 69.7138 46.0162 68.3138 82.6702 56.7038C119.323 45.0938 147.146 27.2138 149.641 14.5178C149.649 14.4778 149.657 14.4368 149.664 14.3958C149.927 12.9708 149.868 11.6108 149.463 10.3338C145.455 -2.32024 109.051 -2.07724 68.1542 10.8768Z"
                        fill="url(#paint2_linear_1_286)"
                        fillOpacity="0.16"
                        stroke="white"
                        strokeOpacity="0.16"
                        strokeWidth="1.21539"
                        strokeLinejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1_286"
                          x1="146.361"
                          y1="-13.8952"
                          x2="51.1242"
                          y2="93.4608"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_1_286"
                          x1="153.619"
                          y1="9.01872"
                          x2="102.597"
                          y2="112.77"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_1_286"
                          x1="146.361"
                          y1="-13.8952"
                          x2="62.8662"
                          y2="94.8348"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="wixard__box">
                    <svg width={356} height={262} viewBox="0 0 356 262" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M237.791 74.484H74.409L-102 -102.468H417.457L237.791 74.484Z"
                        fill="url(#paint0_linear_465_6036)"
                        fillOpacity="0.16"
                      />
                      <g filter="url(#filter0_f_465_6036)">
                        <path
                          d="M180.5 257C261.41 257 327 191.41 327 110.5C327 29.5903 261.41 -36 180.5 -36C99.5903 -36 34 29.5903 34 110.5C34 191.41 99.5903 257 180.5 257Z"
                          fill="url(#paint1_radial_465_6036)"
                          fillOpacity="0.05"
                        />
                      </g>
                      <path
                        d="M237.503 241.123L286.643 195.08V28.347L237.503 74.3891V195.081V241.123Z"
                        fill="url(#paint2_linear_465_6036)"
                        fillOpacity="0.06"
                      />
                      <path
                        d="M123.006 195.081L73.866 241.123V74.3891L123.006 28.347V74.3891V195.081Z"
                        fill="url(#paint3_linear_465_6036)"
                        fillOpacity="0.06"
                      />
                      <path
                        d="M123.006 195.081L73.866 241.123H237.503V195.081H123.006Z"
                        fill="url(#paint4_linear_465_6036)"
                        fillOpacity="0.06"
                      />
                      <path
                        d="M123.006 74.3892V195.081H237.503V74.3892H123.006Z"
                        fill="url(#paint5_linear_465_6036)"
                        fillOpacity="0.06"
                      />
                      <path
                        d="M237.503 74.3891L286.643 28.347H123.006V74.3891H237.503Z"
                        fill="url(#paint6_linear_465_6036)"
                        fillOpacity="0.06"
                      />
                      <path
                        d="M286.643 195.08L237.503 241.123M286.643 195.08V28.347M286.643 195.08L237.503 195.081M237.503 241.123H73.866M237.503 241.123V195.081M286.643 28.347L237.503 74.3891M286.643 28.347H123.006M237.503 195.081H123.006M237.503 195.081V74.3891M73.866 241.123L123.006 195.081M73.866 241.123V74.3891M123.006 195.081V74.3891M73.866 74.3891L123.006 28.347M73.866 74.3891H123.006M123.006 74.3891H237.503M123.006 74.3891V28.347"
                        stroke="white"
                        strokeOpacity="0.24"
                        strokeWidth="1.2"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M123.006 195.081L73.866 241.123H237.503V195.081H123.006Z"
                        fill="url(#paint7_linear_465_6036)"
                        fillOpacity="0.16"
                      />
                      <path
                        d="M237.503 74.3889L286.643 28.3469H123.006V74.3889H237.503Z"
                        fill="url(#paint8_linear_465_6036)"
                        fillOpacity="0.16"
                      />
                      <path
                        d="M123.006 28.3469L73.866 74.3889H123.006V28.3469Z"
                        fill="url(#paint9_linear_465_6036)"
                        fillOpacity="0.16"
                      />
                      <path
                        d="M237.503 241.123L286.643 195.08L237.503 195.081V241.123Z"
                        fill="url(#paint10_linear_465_6036)"
                        fillOpacity="0.16"
                      />
                      <path
                        d="M119.806 195.082C119.806 196.849 121.239 198.282 123.006 198.282C124.774 198.282 126.206 196.849 126.206 195.082C126.206 193.315 124.774 191.882 123.006 191.882C121.239 191.882 119.806 193.315 119.806 195.082ZM283.443 195.082C283.443 196.849 284.876 198.282 286.643 198.282C288.411 198.282 289.843 196.849 289.843 195.082C289.843 193.315 288.411 191.882 286.643 191.882C284.876 191.882 283.443 193.315 283.443 195.082ZM123.006 195.682H286.643V194.482H123.006V195.682Z"
                        fill="white"
                        fillOpacity="0.64"
                      />
                      <path
                        d="M119.806 28.347C119.806 30.114 121.239 31.547 123.006 31.547C124.774 31.547 126.206 30.114 126.206 28.347C126.206 26.579 124.774 25.147 123.006 25.147C121.239 25.147 119.806 26.579 119.806 28.347ZM283.443 28.347C283.443 30.114 284.876 31.547 286.643 31.547C288.411 31.547 289.843 30.114 289.843 28.347C289.843 26.579 288.411 25.147 286.643 25.147C284.876 25.147 283.443 26.579 283.443 28.347ZM123.006 28.947H286.643V27.747H123.006V28.947Z"
                        fill="white"
                        fillOpacity="0.64"
                      />
                      <path
                        d="M234.303 241.123C234.303 242.89 235.736 244.323 237.503 244.323C239.271 244.323 240.703 242.89 240.703 241.123C240.703 239.356 239.271 237.923 237.503 237.923C235.736 237.923 234.303 239.356 234.303 241.123ZM70.666 241.123C70.666 242.89 72.099 244.323 73.866 244.323C75.634 244.323 77.066 242.89 77.066 241.123C77.066 239.356 75.634 237.923 73.866 237.923C72.099 237.923 70.666 239.356 70.666 241.123ZM237.503 240.523H73.866V241.723H237.503V240.523Z"
                        fill="white"
                        fillOpacity="0.64"
                      />
                      <path
                        d="M234.303 74.388C234.303 76.155 235.736 77.588 237.503 77.588C239.271 77.588 240.703 76.155 240.703 74.388C240.703 72.62 239.271 71.188 237.503 71.188C235.736 71.188 234.303 72.62 234.303 74.388ZM70.666 74.388C70.666 76.155 72.099 77.588 73.866 77.588C75.634 77.588 77.066 76.155 77.066 74.388C77.066 72.62 75.634 71.188 73.866 71.188C72.099 71.188 70.666 72.62 70.666 74.388ZM237.503 73.788H73.866V74.988H237.503V73.788Z"
                        fill="white"
                        fillOpacity="0.64"
                      />
                      <defs>
                        <filter
                          id="filter0_f_465_6036"
                          x={-146}
                          y={-216}
                          width={653}
                          height={653}
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood floodOpacity={0} result="BackgroundImageFix" />
                          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                          <feGaussianBlur stdDeviation={90} result="effect1_foregroundBlur_465_6036" />
                        </filter>
                        <linearGradient
                          id="paint0_linear_465_6036"
                          x1="157.729"
                          y1={-102}
                          x2="157.729"
                          y2="153.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#030D0C" stopOpacity={0} />
                          <stop offset={1} stopColor="white" />
                        </linearGradient>
                        <radialGradient
                          id="paint1_radial_465_6036"
                          cx={0}
                          cy={0}
                          r={1}
                          gradientUnits="userSpaceOnUse"
                          gradientTransform="translate(180.5 113.35) rotate(90) scale(343.448)"
                        >
                          <stop />
                          <stop offset={1} stopOpacity={0} />
                        </radialGradient>
                        <linearGradient
                          id="paint2_linear_465_6036"
                          x1="180.255"
                          y1="28.347"
                          x2="180.255"
                          y2="241.123"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint3_linear_465_6036"
                          x1="180.255"
                          y1="28.347"
                          x2="180.255"
                          y2="241.123"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint4_linear_465_6036"
                          x1="180.255"
                          y1="28.3471"
                          x2="180.255"
                          y2="241.123"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint5_linear_465_6036"
                          x1="180.255"
                          y1="28.3472"
                          x2="180.255"
                          y2="241.123"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint6_linear_465_6036"
                          x1="180.255"
                          y1="28.347"
                          x2="180.255"
                          y2="241.123"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint7_linear_465_6036"
                          x1="292.614"
                          y1="28.3471"
                          x2="73.866"
                          y2="75.5701"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint8_linear_465_6036"
                          x1="292.614"
                          y1="28.3469"
                          x2="73.8661"
                          y2="75.5699"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint9_linear_465_6036"
                          x1="292.614"
                          y1="28.3469"
                          x2="73.866"
                          y2="75.5699"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient
                          id="paint10_linear_465_6036"
                          x1="292.614"
                          y1="28.3471"
                          x2="73.8659"
                          y2="75.5701"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset={1} stopColor="white" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="wixard__x">
                    <svg width={298} height={258} viewBox="0 0 298 258" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M191.121 198.799C179.436 180.515 160.014 167.54 136.695 167.559C127.665 167.567 119.293 171.011 111.207 174.637C139.891 153.75 129.241 116.052 117.191 89.4447C130.244 111.788 157.035 127.332 181.278 114.03C167.636 142.773 176.464 172.598 191.121 198.799Z"
                        stroke="url(#paint0_linear_1_285)"
                        strokeWidth="4.87"
                        strokeMiterlimit="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1_285"
                          x1="110.536"
                          y1="173.646"
                          x2="186.725"
                          y2="122.077"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#22F7DD" />
                          <stop offset={1} stopColor="#B4FDC2" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutQuestions;
