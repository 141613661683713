import React from "react";
function AboutIntro() {
  return (
    <>
      <section className="aboutIntro lightning-none">
        <div className="container">
          <div className="aboutIntro__wrapper">
            <div className="homeIntro__content">
              <h1 className="homeIntro__headline">
                We are the
                <span className="homeIntro__highlight">
                  <span className="homeIntro__highlight-container">
                    <span className="homeIntro__highlight-wizards">
                      wizards
                    </span>
                    <span className="homeIntro__highlight-wixards">
                      wixards
                    </span>
                  </span>
                </span>
                of the
                <br />
                web, merging creativity and technology.
              </h1>
            </div>
            <div className="verticalIndustries verticalIndustries__lg">
              <div className="verticalIndustries__card">
                <ul className="verticalIndustries__list">
                  <li className="verticalIndustries__item">Branding</li>
                  <li className="verticalIndustries__item">Startups</li>
                  <li className="verticalIndustries__item">Web3</li>
                  <li className="verticalIndustries__item active">UI - UX</li>
                  <li className="verticalIndustries__item">Development</li>
                  <li className="verticalIndustries__item">Blokchain</li>
                  <li className="verticalIndustries__item">Application</li>
                </ul>
              </div>
              <div className="verticalIndustries__brand">
                <img
                  width={200}
                  height={50}
                  src="./assets/icons/logo.svg"
                  alt="wixard logo"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutIntro;
