import React from "react";
function WorksIntro() {
  return (
    <>
      <section className="homeIntro secondary">
        <div className="container">
          <div className="homeIntro__content">
            <h1 className="homeIntro__headline">
              We create <span>magic</span> <br />
              that elevates businesses
              <span>beyond the ordinary.</span>
            </h1>
          </div>
        </div>
      </section>
    </>
  );
}

export default WorksIntro;
