import logo from "./logo.svg";
import Spline from "@splinetool/react-spline";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import HomeBody from "./pages/HomeBody";
import AboutBody from "./pages/AboutBody";
import WorksBody from "./pages/WorksBody";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
function App() {
  return (
    <>
      <Router>
        <Header />

        <Routes>
          <Route path="/" exact element={<HomeBody />}></Route>
          <Route path="/about" element={<AboutBody />}></Route>
          <Route path="/works" element={<WorksBody />}></Route>
        </Routes>

        <Footer />
      </Router>
    </>
  );
}

export default App;
